@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  .font-outline {
    -webkit-text-stroke: 1px #282870;
  }
  .font-outline-2 {
    -webkit-text-stroke: 2px black;
  }
  .font-outline-4 {
    -webkit-text-stroke: 4px black;
  }
}
